<template>
  <div class="entity-item flex flex-start" style="borderRadius: 12px; background: #ffffff">
    <div class="img">
      <img class="img" :src="data.img" />
      <!-- 图片左上角 Tag -->
      <div class="img-tag flex flex-center" v-if="lo.get(data, 'raw.groupTourTypeName', false)">
        <p class="img-tag-text">{{ data.raw.groupTourTypeName }}</p>
      </div>
      <!-- 图片底部文字 -->
      <div class="img-start flex flex-center" v-if="lo.get(data, 'raw.depPlaceNames', false)">
        <p class="img-start-text">{{ data.raw.depPlaceNames }}出发</p>
      </div>
    </div>

    <div class="container">
      <p class="title overflow">{{ data.title }}</p>
      <p class="desc overflow">{{ data.desc }}</p>
      <p class="kind overflow" v-if="data.position">{{ data.position }}</p>
      <p class="kind overflow" v-if="data.productTags && !['125026', '126064', '126065', '126068', '126139', '126140'].includes(data.productId)">
        {{ data.productTags }}
      </p>
      <div class="tag flex flex-center flex-start">
        <div class="tag-item flex flex-center" v-for="(item, index) in data.tags" :key="item.text + index" :style="{ borderColor: item.color || '' }">
          <p class="tag-item-text" :style="{ color: item.color || '' }">{{ item.text }}</p>
        </div>
      </div>
      <div class="channel-price flex flex-between">
        <template v-if="data.bottomTags">
          <p class="channel" v-for="(item, index) in data.bottomTags" :key="item.text + index" :style="{ color: item.color || '' }">{{ item.text }}</p>
        </template>
        <p class="channel" v-if="data.channelName">{{ data.channelName }}</p>
        <p v-if="!data.bottomTags && !data.channelName"></p>
        <p class="price">
          <span class="price-sub">{{ data.pricePerfixed }}</span>
          <span class="price-main">{{ data.price }}</span>
          <span class="price-sub">{{ data.priceSuffix }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// img: b.img,
// title: b.scenicSpotName,
// desc: b.description,
// position: b.close.join('｜'),
// tag: b.tags.map(tag => ({ text: tag.name, color: tag.colour})),
// bottomTags: b.saleTags.map(tag => ({ text: tag.name, color: tag.colour})),
// price: b.salePrice,
// pricePerfixed: b.pricePerfixed,
// priceSuffix: b.priceSuffix
import _ from "lodash";

export default {
  props: {
    data: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      lo: _,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/reset.scss";
@import "@/scss/style.scss";
@import "../common.scss";
.img {
  flex-shrink: 0;
  width: 100 * $px;
  height: 120 * $px;
  border-radius: 12 * $px;
  // background-position: center center;
  // background-size: cover;
  object-fit: cover;
  position: relative;
  overflow: hidden;
}

.img-tag {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 42 * $px;
  height: 18 * $px;
  background: linear-gradient(159deg, #ff5e19 11%, #ff9040 86%);
  border-radius: 8 * $px 0px 8 * $px 0px;
  padding: 0 6 * $px;
  box-sizing: border-box;
  .img-tag-text {
    // position: absolute;
    // top: 0;
    font-size: 11 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #ffffff;
    transform-origin: top;
    transform: scale(0.9);
  }
}

.img-start {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 12 * $px;
  border-bottom-right-radius: 12 * $px;
  .img-start-text {
    font-size: 11 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #ffffff;
    transform-origin: center;
    transform: scale(0.9);
  }
}

.container {
  flex-grow: 1;
  margin-left: 12 * $px;
  position: relative;
  overflow: hidden;
  height: 120 * $px;
}

.title {
  font-size: 15 * $px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #262626;
  margin-bottom: 4 * $px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.desc {
  font-size: 12 * $px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #262626;
  margin-bottom: 4 * $px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.kind {
  font-size: 12 * $px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: rgba(52, 56, 75, 0.75);
  margin-bottom: 4 * $px;
}

.tag-item {
  height: 16 * $px;
  border: 1px solid rgba(0, 120, 255, 0.5);
  border-radius: 4 * $px;
  padding: 0 3 * $px;
  margin-right: 4 * $px;
  margin-top: 4 * $px;
  flex-shrink: 0;
}

.tag-item-text {
  font-size: 11 * $px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #0078ff;
}

.channel-price {
  width: 100%;
  position: absolute;
  bottom: 0;
  line-height: 17 * $px;
  vertical-align: baseline;
}

.channel {
  font-size: 11 * $px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: rgba(52, 56, 75, 0.3);
}

.price-main {
  font-size: 18 * $px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #ff6814;
}

.price-sub {
  font-size: 12 * $px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #ff6814;
}
</style>
