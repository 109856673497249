<template>
  <div class="root">
    <img class="banner" src="https://cdn1.133.cn/config/hltrip/image/concertBanner1.png" />
    <img class="seatingChart" src="https://cdn1.133.cn/config/hltrip/image/concertSeating1.png" />
    <div class="title">
      <span class="title-txt1">请注意！！！</span>
      <span class="title-txt2">产品一经购买 不可退订！</span>
    </div>
    <div class="item" v-for="(item, index) in list">
      <img class="item-img" :src="item.titleImg" />
      <div class="list-card" @click="jumpToDetail(item)">
        <entity-item :data="item"></entity-item>
      </div>
      <div class="list-card mT" v-if="index == 0" @click="jumpToDetail(cat1Obj)">
        <entity-item :data="cat1Obj"></entity-item>
      </div>
    </div>
  </div>
</template>
<script>
import nativeApi from "@/utils/nativeApi";
import EntityItem from "../list/components/entity-item";
import env from "@/utils/env";
import uid from "@/boot/uid";

export default {
  data() {
    return {
      cat1Obj: {
        titleImg: "https://cdn1.133.cn/config/hltrip/image/dayOne.png",
        raw: {
          depPlaceNames: "全国",
          groupTourTypeName: "精品小团",
          category: "跟团游",
        },
        img: "https://intticket.oss-cn-beijing.aliyuncs.com/周杰伦.png",
        title: "12月9日 CAT1周杰伦曼谷演唱会内场票套票",
        desc: "超值内场，近距离接触偶像",
        channelName: "",
        price: "4000",
        pricePerfixed: "¥",
        priceSuffix: "起",
        productId: "126064",
      },

      list: [
        {
          titleImg: "https://cdn1.133.cn/config/hltrip/image/dayOne.png",
          raw: {
            depPlaceNames: "全国",
            groupTourTypeName: "精品小团",
            category: "跟团游",
          },
          img: "https://intticket.oss-cn-beijing.aliyuncs.com/周杰伦.png",
          title: "12月8日 CAT1周杰伦曼谷演唱会内场票套票",
          desc: "100%出演唱会原价票，安全放心",
          channelName: "",
          price: "4000",
          pricePerfixed: "¥",
          priceSuffix: "起",
          productId: "125026",
        },
        {
          titleImg: "https://cdn1.133.cn/config/hltrip/image/dayTwo.png",
          raw: {
            depPlaceNames: "全国",
            groupTourTypeName: "精品小团",
            category: "跟团游",
          },
          img: "https://intticket.oss-cn-beijing.aliyuncs.com/周杰伦2(1).png",
          title: "12月9日 CAT2周杰伦曼谷演唱会内场票套票",
          desc: "100%内场区域，视觉音效感受好",
          channelName: "",
          price: "2599",
          pricePerfixed: "¥",
          priceSuffix: "起",
          productId: "126065",
        },
        {
          titleImg: "https://cdn1.133.cn/config/hltrip/image/dayThree.png",
          raw: {
            depPlaceNames: "全国",
            groupTourTypeName: "精品小团",
            category: "跟团游",
          },
          img: "https://intticket.oss-cn-beijing.aliyuncs.com/周杰伦2(1).png",
          title: "12月8日 CAT3周杰伦曼谷演唱会看台票套票",
          desc: "最佳看台火爆抢购中",
          channelName: "",
          price: "1999",
          pricePerfixed: "¥",
          priceSuffix: "起",
          productId: "126068",
        },
        {
          titleImg: "https://cdn1.133.cn/config/hltrip/image/dayFour.png",
          raw: {
            depPlaceNames: "全国",
            groupTourTypeName: "精品小团",
            category: "跟团游",
          },
          img: "https://intticket.oss-cn-beijing.aliyuncs.com/周杰伦2(1).png",
          title: "12月8日 CAT4周杰伦曼谷演唱会看台票套票",
          desc: "正中间视野看台票，位置好",
          channelName: "",
          price: "1799",
          pricePerfixed: "¥",
          priceSuffix: "起",
          productId: "126139",
        },
        {
          titleImg: "https://cdn1.133.cn/config/hltrip/image/dayFive.png",
          raw: {
            depPlaceNames: "全国",
            groupTourTypeName: "精品小团",
            category: "跟团游",
          },
          img: "https://intticket.oss-cn-beijing.aliyuncs.com/周杰伦2(1).png",
          title: "12月8日 CAT5周杰伦曼谷演唱会看台票套票",
          desc: "实惠价格坐席票",
          channelName: "",
          price: "1299",
          pricePerfixed: "¥",
          priceSuffix: "起",
          productId: "126140",
        },
      ],
    };
  },
  components: { EntityItem },
  methods: {
    init() {
      nativeApi.invoke("updateHeaderRightBtn", {
        action: "show",
        icon: "iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAEj0lEQVRoQ+2ZXWhcRRTH/2d2Q6yssljBItEuGNndO+NH3VrBF2sR6wd5qEIFXyxVq4gPVkVb9EFBhQpFweZBU6kWBFEUH5QqiFYpSlEq2pl7k2CC2hA/cQlqukn23iMTbmSzvZu9Ye9NDGSelp0zZ37/c2bunDuXsMIbrXB+rApY7gyuZiChDJBS6jAzZ5h5l+u6f8f1+7/IQLFYvDCbzZ4iIsHMx5n5hrgi2gooFAr5XC53ZZyI+L4/5Hnez3Fsm22UUtsAvA3AZiG2iJYCKpVK19TU1AMAngKQjwl1ul6vXzM4OHgypv08s1DEWwCycUVECujp6VmTz+c/B7BxsSDMvMkY89Vix83ZK6VuBfDenIhqtbplfHx8spW/SAFKqecA7A0HHWXm14jox3ZQzPxPJ/AtRByrVqtbW4mIEkBSyt+JaC0zf2qM2dIOPI3+pky0FHGGgHK5vD6TyfxgoXzf3+Z5nk1naq1QKJyVy+UUgFzzJMzcR0QP2/+Z+djExMSNY2NjpxvtzhBQKpUuy2az31mjIAiucl33myTp7cOhVqvdRUSbmXkDERXtkyfOHMz8vDHm8WURUCwWz+nq6rqPmR8honVxgCMycsQYc8uSC3Ac52YiOkxE5zdMPgPgWwBfMvMXRPRLM3AQBGuEEIcAXBAuo13GmIElExA+jg8A2Nkw6a8AXpqcnDwwOjo60SoTjuPkhBAfAbg2hH/RGLO72T7NPSCklB8Q0U0hwDSAPczc77qu/d2yWXgi+oSIrl4I3valJsBxnINCiLtDAFOv128fGhoaarf2FwOfmgAp5VYi+jCEHZuent4wPDz8Rzv43t7ec7u7uz+OE/k5X6lkQCllayH7bLclwEattRcT/jMimi0cmTlyzae+B5RSdwB4M4TYZ4zZ0w7e9ksp+4nIFo+x4VNZQlLKI3bjMnNtZmbmojhLJxTwEBG9EHVYLRSARJdQeFhVw5q+3xjzYJzod2KTqADHce4UQrxhgXzfv87zPFuSp9oSFSClHCCie5j5T2OMPXU5VfqkzwGl1NcAKvbVUGu9PW34pDexUErVAHQBeFJr/WxSAsLX2yu01jZA81piS8hxHCmE0KH3Pq31+0kJUEo9AeAZAIe01o11VXKlROPpGwTBpa7rfp+UACnlK0R0L4BTWuuLG/0mmYEdYekLrbVdRvWVJmCvEMJeBlS11uclBR8eculnQEq5j4geA3BSa335ihOglLoEwEFmftkYM1sLJdWWZA8kBRvlZ1VAmtGN43tRGSiXyyqTycxezvq+X/E870ScSdK0aaixfjLGrF/wHHAcZ50QYvaKPAiC7a7r2ivvZW1SyuNEtAnACa21rbX+a5GXu1JKl4jKAI5qra9fTnql1G0A3gkZ9mutH20rQCll641XraG94AXwepzb6SSF+r5/thDifiLqCzmmgiAoep4375a85QcOKeV+ALuJqO1XnCTBo3wx819EtENr/W5z/4JwpVKpkslk7GvhZiIqpA0a4X+GmQdqtdrTIyMjv0XNv+zR7TQoqwI6jWCn41cz0GkEOx3/L8p3008HVuGMAAAAAElFTkSuQmCC",
        text: "分享",
      });
      let that = this;
      nativeApi.on("headerRightBtnClick", (data) => {
        that.share();
      });
    },

    share() {
      if (process.env.NODE_ENV == "production") {
        window.ha("send", "click", {
          bizsys: "gtyy",//业务部门
          actId: "concertjayzhou",//活动id
          eventId: "concertjayzhou_share_click",//事件id
        });
      }

      nativeApi.invoke("sharePage", {
        title: "周杰伦 | 曼谷站·2023世界巡回演唱会平价套票抢购中",
        desc: "100%真实出票，数量有限，速抢 →",
        link: `https://h5.133.cn/hltrip/concert-activity?hlsource=${env.hlsource}&analyseSourceEntry=${env.hlsource}`,
        imgUrl: "https://cdn1.133.cn/config/hltrip/image/shareBanner.png",
        picImgUrl: "https://cdn1.133.cn/config/hltrip/image/shareBanner.png",
        type: "weixin,pengyouquan,qq,qqzone",
      });
    },

    jumpToDetail(item) {
      let page = {
        url: "/tour-group-detail",
        name: "tour-group-detail",
        query: {
          analyseSourceEntry: env.hlsource || "",
          hlsource: env.hlsource || "",
          productId: item.productId || "",
        },
      };
      this.$page.push(page);
    },
  },
  created() {
    nativeApi.invoke("setNavigationBarVisible", {
      value: "1",
      forbiddenanimated: "1",
    });

    this.$updateTitle("2023周杰伦世巡曼谷站");

    this.init();
    if (process.env.NODE_ENV == "production") {
      // 设置统计业务类型，对应个推的bizsys参数
      window.ha("create", "h5");
      window.ha("set", "uid", uid.uid);
      window.ha("set", "uuid", uid.uuid);
      window.ha("send", "pageview", {
        eventId: window.location.pathname,
        eventAttr: JSON.stringify({ url: window.location.href }),
      }); // todo
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "../../scss/style";
@import "../../scss/common";
.mT {
  margin-top: 8 * $px;
}
.root {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #000000;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 50 * $px;
}
.banner {
  width: 375 * $px;
  height: 590 * $px;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  box-sizing: border-box;
  z-index: 1;
}
.seatingChart {
  width: 341 * $px;
  height: 653 * $px;
  margin-top: 536 * $px;
  margin-left: 17 * $px;
  margin-bottom: 17 * $px;
  position: relative;
  z-index: 10;
}
.title {
  width: 375 * $px;
  height: 78 * $px;
  background: linear-gradient(-90deg, #ffc682, #ff1e1a);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 51 * $px;
  margin-bottom: 77 * $px;
  box-sizing: border-box;
  .title-txt1 {
    font-weight: 500;
    font-size: 23 * $px;
    text-align: left;
    color: #ffffff;
    margin-bottom: 7 * $px;
  }
  .title-txt2 {
    font-weight: 500;
    font-size: 23 * $px;
    text-align: left;
    color: #ffffff;
  }
}

.list-card {
  //   width: 363 * $px;
  background: #fff;
  border-radius: 8 * $px;
  padding: 8 * $px 10 * $px;
  box-sizing: border-box;
  margin-left: 6 * $px;
  margin-bottom: 6 * $px;
}
.item {
  padding-left: 17 * $px;
  padding-right: 17 * $px;
  margin-bottom: 39 * $px;
  .item-img {
    width: 179 * $px;
    height: 68 * $px;
    margin-left: 24 * $px;
    margin-bottom: 29 * $px;
  }
}
</style>
